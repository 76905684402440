import ceMarker from '../../assets/images/centredil-logo-marker.svg'
import netMarker from '../../assets/images/network-marker-gruppo.svg'
import wsMarker from '../../assets/images/worksite_marker.svg'
import MarkerClusterer from '@googlemaps/markerclustererplus';

function CEMarker() {
    return {
        path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
        fillColor: '#FF0000',
        fillOpacity: .6,
        anchor: new google.maps.Point(0,0),
        strokeWeight: 0,
        scale: 1
    }
}

function pointsMap(CEPoints, otherPoints, centerPoint) {

    centerPoint = typeof centerPoint !== 'undefined' ? centerPoint : null;
    if (centerPoint == null){
        var UserCoords = getLocation({lat: CEPoints[0][0], lng: CEPoints[0][1]})
    } else {
        var UserCoords = {lat: centerPoint[0], lng: centerPoint[1]}
    }

    var marker = null;
    var markers = [];
    var myCoords = new google.maps.LatLng(UserCoords.lat, UserCoords.lng);
    var mapOptions = {
        center: myCoords,
        zoom: 9
    };
    var map = new google.maps.Map(document.getElementById('map'), mapOptions);



    CEPoints.forEach(function(point) {
        marker = new google.maps.Marker({
            position: new google.maps.LatLng(point[0], point[1]),
            url: point[2],
            map: map,
            icon: ceMarker
        });
        markers.push(marker);
        google.maps.event.addListener(marker, 'click', function() {
            window.location.href = this.url;
        });
    });

    otherPoints.forEach(function(point) {
        marker = new google.maps.Marker({
            position: new google.maps.LatLng(point[0], point[1]),
            url: point[2],
            map: map,
            icon: netMarker
        });
        markers.push(marker);
        google.maps.event.addListener(marker, 'click', function() {
            window.location.href = this.url;
        });
    });

    const markerCluster = new MarkerClusterer(map, markers, {
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    });

}

window.pointsMap = pointsMap;


function routeMap(startLat, startLng, destLat, destLng, CEPoints, otherPoints) {

    var pointA = new google.maps.LatLng(startLat, startLng),
        pointB = new google.maps.LatLng(destLat, destLng),
        myOptions = {
            zoom: 12,
            center: pointA,
            suppressMarkers: true
        },
        map = new google.maps.Map(document.getElementById('map'), myOptions),
        // Instantiate a directions service.
        directionsService = new google.maps.DirectionsService,
        directionsDisplay = new google.maps.DirectionsRenderer({
            suppressMarkers: true,
            map: map
        });

        var marker = null;
        var markers = [];


        var markerA = new google.maps.Marker({
            position: pointA,
            map: map,
            icon: wsMarker
        });

        CEPoints.forEach(function(point) {
            marker = new google.maps.Marker({
                position: new google.maps.LatLng(point[0], point[1]),
                url: point[2],
                map: map,
                icon: ceMarker
            });
            markers.push(marker);
            google.maps.event.addListener(marker, 'click', function() {
                window.location.href = this.url;
            });

        });

        otherPoints.forEach(function(point) {
            marker = new google.maps.Marker({
                position: new google.maps.LatLng(point[0], point[1]),
                url: point[2],
                map: map,
                icon: netMarker
            });
            markers.push(marker);
            google.maps.event.addListener(marker, 'click', function() {
                window.location.href = this.url;
            });
        });

    const markerCluster = new MarkerClusterer(map, markers, {
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    });


    calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB);
}

window.routeMap = routeMap;

function calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB) {
    directionsService.route({
        origin: pointA,
        destination: pointB,
        avoidTolls: true,
        avoidHighways: false,
        travelMode: google.maps.TravelMode.DRIVING
    }, function (response, status) {
        if (status == google.maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(response);
        } else {
            window.alert('Directions request failed due to ' + status);
        }
    });
}


function getLocation(defaultCoord) {
    var UserCoords = defaultCoord
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
            UserCoords.lat = position.coords.latitude;
            UserCoords.lng = position.coords.longitude;
        });
    }
    return UserCoords
}